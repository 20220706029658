import React from 'react'
import clsx from 'clsx'

import * as styles from './featuresGridIcon.module.scss'
import {
  AiOutlineRocket,
  AiOutlineFall,
  AiOutlineUsergroupAdd,
  AiOutlineFileProtect,
  AiOutlineLike,
  AiOutlineSafety,
  AiOutlineAlert,
} from 'react-icons/ai'
import {MdOutlineTrendingUp} from 'react-icons/md'
import { Variants, motion } from 'framer-motion'
import i18n from '../../i18n'

const items = [
  {
    title: `${i18n[`common.performance`]}`,
    text: `${i18n[`common.performanceText`]}`,
    icon: <AiOutlineRocket />,
  },
  {
    title: `${i18n[`common.quality`]}`,
    text: `${i18n[`common.qualityText`]}`,
    icon: <AiOutlineUsergroupAdd />,
  },
  {
    title: `${i18n[`common.compliance`]}`,
    text: `${i18n[`common.complianceText`]}`,
    icon: <AiOutlineFileProtect />,
  },
  {
    title: `${i18n[`common.costReduction`]}`,
    text:  `${i18n[`common.costReductionText`]}`,
    icon: <MdOutlineTrendingUp />,
  },
  {
    title: `${i18n[`common.businessRisk`]}`,
    text: `${i18n[`common.businessRiskText`]}`,
    icon: <AiOutlineAlert />,
  },
  {
    title: `${i18n[`common.safety`]}`,
    text: `${i18n[`common.safetyText`]}`,
    icon: <AiOutlineSafety />,
  },
]

const listItemVariants: Variants = {
  open: (i) => ({
    left: 0,
    opacity: 1,
    transition: {
      delay: i * 0.2,
      duration: 0.5,
      type: 'spring',
    },
  }),
  closed: {
    left: -300,
    opacity: 0,
  },
}

const listItemVariantsInverted: Variants = {
  open: (i) => ({
    right: 0,
    opacity: 1,
    transition: {
      delay: i * 0.2,
      duration: 0.5,
      type: 'spring',
    },
  }),
  closed: {
    right: -300,
    opacity: 0,
  },
}

export const FeaturesGridIcon: React.FC = () => {
  return (
    <div className={clsx(styles.featuresGrid)}>
      {items.map((item, key) => (
        <motion.div
          custom={key}
          whileInView="open"
          initial="closed"
          variants={key % 2 === 0 ? listItemVariants : listItemVariantsInverted}
          viewport={{ once: true }}
          className={styles.featuresItem}
          key={key}
        >
          <div className={styles.icon}>{item.icon}</div>
          <div className={styles.text}>
            <h3>{item.title}</h3>
            <p>{item.text}</p>
          </div>
        </motion.div>
      ))}
    </div>
  )
}
