import clsx from 'clsx'
import React from 'react'

import * as styles from './sectionTitle.module.scss'

type DivType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

// @ts-ignore
interface SectionTitleProps extends DivType {
  title: React.ReactNode
  position: `center` | `left` | `right`
  color: `white` | `darkBlue` | `deepDarkBlue`
  description?: string | React.ReactNode
  imageAsset?: string
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  position,
  color,
  description,
  imageAsset,
  ...props
}) => {
  return (
    <div className={clsx(`container`, styles.titleContent)} {...props}>
      <div
        className={clsx('row', {
          'd-flex align-items-center': imageAsset !== undefined,
        })}
      >
        <div className="col py-4 py-lg-5">
          <div
            className={clsx(props.className, {
              [styles.sectionTitle]: true,
              [styles.textCenter]: position === 'center',
              [styles.textLeft]: position === 'left',
              [styles.textRight]: position === 'right',
              [styles.white]: color === `white`,
              [styles.darkBlue]: color === `darkBlue`,
              [styles.deepDarkBlue]: color === `deepDarkBlue`,
              [styles.hasImageAsset]: imageAsset !== undefined,
            })}
          >
            {title}
            {description && <p>{description}</p>}
          </div>
        </div>
        {imageAsset && (
          <div className={clsx('col-12 col-sm-4', styles.imageAsset)}>
            <img src={imageAsset} alt="title asset" />
          </div>
        )}
      </div>
    </div>
  )
}
