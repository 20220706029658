// extracted by mini-css-extract-plugin
export var darkBlue = "sectionTitle-module--darkBlue--n+0Cg";
export var deepDarkBlue = "sectionTitle-module--deepDarkBlue--JPJuC";
export var hasImageAsset = "sectionTitle-module--hasImageAsset--kAxdL";
export var imageAsset = "sectionTitle-module--imageAsset--aa9CP";
export var sectionTitle = "sectionTitle-module--sectionTitle--HTois";
export var textCenter = "sectionTitle-module--textCenter--DjUpm";
export var textLeft = "sectionTitle-module--textLeft--Ea2cF";
export var textLeftInvert = "sectionTitle-module--textLeftInvert--jMXns";
export var textRight = "sectionTitle-module--textRight--jF63W";
export var titleContent = "sectionTitle-module--titleContent--n-NNo";
export var white = "sectionTitle-module--white--Jx0D4";